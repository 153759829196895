import { sanitizeFactory } from '@utils/sanitize.factory';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';
import moment from 'moment-timezone';
import { toBoolean } from '@utils/to-boolean';
import { identity } from 'lodash';
import { toISODay } from '@utils/dates/to-iso-day';
import { serializeFactory } from '@utils/serialize.factory';

export type FormModel = Pick<
  IAnnualSupportCharge,
  | 'numberOfSupportYear'
  | 'annualSupportInclVat'
  | 'annualSupportExVat'
  | 'billingDate'
  | 'cancellationReason'
  | 'isCancelled'
  | 'invoice'
>;

export type ApiModel = Pick<
  IAnnualSupportCharge,
  | 'numberOfSupportYear'
  | 'annualSupportInclVat'
  | 'annualSupportExVat'
  | 'billingDate'
  | 'cancellationReason'
  | 'isCancelled'
  | 'invoice'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  numberOfSupportYear: { type: 'transportable', initial: 0 },
  annualSupportExVat: { type: 'transportable', initial: 0 },
  annualSupportInclVat: { type: 'transportable', initial: 0 },
  billingDate: { type: 'date', initial: moment() },
  cancellationReason: { type: 'backend-label', optionKey: 'ascCancellationReasonOptions', initial: null },
  isCancelled: { type: 'transportable', initial: false },
  invoice: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  numberOfSupportYear: parseInt,
  annualSupportExVat: parseFloat,
  annualSupportInclVat: parseFloat,
  billingDate: toISODay,
  isCancelled: toBoolean,
  cancellationReason: identity,
  invoice: identity,
});
