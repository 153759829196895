import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { toISODay } from '@utils/dates/to-iso-day';
import { toBoolean } from '@utils/to-boolean';
import { SystemOptions } from '@features/order/shared/system.type';
import { InstallationOptions } from '@features/order/shared/installation.type';
import { ApiModel as TechnicalApiModel, FormModel as TechnicalFormModel } from '../technical/form-config';
import { RepairAndReplaceType, RepairAndReplaceTypeOptions } from './repair-and-replace-type.type';

export type FormModel = Omit<TechnicalFormModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

export type ApiModel = Omit<TechnicalApiModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

// TODO copy-pasted and extended from tech order for now
export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  isContributePayable: { type: 'transportable', initial: false },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  warranty: { type: 'backend-label', optionKey: 'warrantyOptions', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  paymentLink: { type: 'transportable', initial: '' },
  salesId: { type: 'transportable', initial: '' },
  pickUp: { type: 'date', initial: null },
  arranged: { type: 'date', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
  rnrQuotationFile: { type: 'transportable', initial: null },
  isUpgradedEquipment: { type: 'transportable', initial: false },
  dsa2ApprovedAmount: { type: 'transportable', initial: '' },
  upgradePrice: { type: 'transportable', initial: '' },
  system: { type: 'enum', options: SystemOptions, initial: null },
  installationType: { type: 'enum', options: InstallationOptions, initial: null },
  immyBotInstallationLink: { type: 'transportable', initial: '' },
  repairAndReplaceType: { type: 'enum', options: RepairAndReplaceTypeOptions, initial: null },
});

// TODO copy-pasted and extended from tech order for now
export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
  isContributePayable: toBoolean,
  organisationId: identity,
  warranty: identity,
  quoteId: identity,
  paymentLink: identity,
  salesId: identity,
  pickUp: toISODay,
  arranged: toISODay,
  invoiceNumber: identity,
  rnrQuotationFile: identity,
  isUpgradedEquipment: toBoolean,
  dsa2ApprovedAmount: identity,
  upgradePrice: identity,
  system: identity,
  installationType: identity,
  immyBotInstallationLink: identity,
  repairAndReplaceType: identity,
});
