import { createEnumOptions } from '@utils/create-enum.factory';

export const Installations = {
  SelfInstallation: 1,
  WorkshopInstallation: 2,
  None: 3,
} as const;

type Keys = keyof typeof Installations;

export type InstallationType = (typeof Installations)[Keys];

export const InstallationOptions = createEnumOptions(Installations);
