import { Failure } from 'superstruct';
import { concatPath } from './concat-path';

/**
 * Create human readable message of superstruct failure
 *
 * @param {Failure} failure superstruct failure type
 * @param {string} prefix page name or endpoint
 * @returns {string}
 */
export function createFailureMessage(failure: Failure, prefix: string): string {
  const path = concatPath(failure.path);
  const value = failure.branch.find((value) => 'id' in value);

  const id = `[id=${value?.id}] ` ?? '';

  return `${prefix} ${id}${path}: ${failure.message}`;
}
