import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosBaseQuery, axiosBaseQuery } from '@api/http-query-client';
import { EndpointBuilder, MutationDefinition, QueryDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { OmitFromUnion } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { buildGetZohoLink, buildRequestZohoLogout } from '@features/zoho-authorization/api';
import { buildCreateAllocation, buildSaveAllocation } from '@features/allocations/save-allocation-form/api';
import { buildGetAllocations } from '@features/allocations/filter-allocations-form/api';
import { buildGetOrderAllocations } from '@features/allocations/api';
import { buildGetModelProgressRecords } from '@features/action-records/api';
import {
  buildCreateOrderContactRecord,
  buildGetOrderContactRecords,
} from '@features/order/shared/order-action-records/api';
import { buildGetEmailTemplates } from '@features/customer/email-templates/api';
import { buildGetTutor, buildGetTutorFeedbackSummaries, buildGetTutors } from '@features/tutors/api';
import { buildGetSessions } from '@features/sessions/filter-sessions-form/api';
import { buildCreateSession, buildSaveSession } from '@features/sessions/save-session-form/api';
import { buildGetAllocationSessions } from '@features/sessions/api';
import { buildGetCustomer } from '@features/customer/api';
import { buildCreateCustomer, buildSaveCustomer } from '@features/customer/save-customer-form/api';
import { buildCreateOrder, buildGetOrder, buildSaveOrder } from '@features/order/api';
import { buildGetDeliveryCalendar } from '@features/delivery-calendar/api';
import { buildGetOrderSummaries } from '@features/order-summaries/filter-order-summaries-form/api';
import { buildGetAssessmentOrderSummaries } from '@features/order-summaries/filter-assessment-order-summaries-form/api';
import { buildRegenerateTimesheet, buildUploadTimesheet } from '@features/sessions/timesheet-info/api';
import { buildGetOrderTypeStatusSummaries, buildGetOrderTypeSummaries } from '@features/dashboard/api';
import { buildGetLabels } from '@features/backend-label/api';
import { buildGetAssessor, buildGetAssessorFeedbackSummaries, buildGetAssessors } from '@features/assessors/api';
import { baseUrl } from '@environment/config';
import { buildConfirmPassword, buildVerifyEmail } from '@features/auth/reset/api';
import { buildLogin } from '@features/auth/login/api';
import { buildGetProduct, buildSaveProduct } from '@features/product/api';
import { buildGetProducts } from '@features/product/filter-products-form/api';
import {
  buildGetSensitivePersonalInformationPresence,
  buildRequestSensitivePersonalInformation,
  buildSaveSensitivePersonalInformation,
} from '@features/order/needs-assessment/sensitive-personal-information/api';
import { buildGetQuote } from '@features/quote/api';
import { buildGetQuotes } from '@features/quote/filter-quote-form/api';
import { buildGetAppointments } from '@features/order/needs-assessment/appointment/api';
import {
  buildCreateAppointment,
  buildGetFilteredAssessorsForOrderId,
  buildSaveAppointment,
} from '@features/order/needs-assessment/appointment/save-appointment-form/api';
import { buildGetZohoFreeSlots } from '@features/automatic-booking/api';
import { buildConvertToPdfReport, buildGetReports, buildSendReport } from '@features/order/needs-assessment/report/api';
import { buildCreateReport, buildSaveReport } from '@features/order/needs-assessment/report/save-report/api';
import {
  buildRefreshOrderElectronicInvoice,
  buildSendOrderElectronicInvoice,
} from '@features/order/shared/order-electronic-invoice/api';
import {
  buildGenerateSessionElectronicInvoice,
  buildRefreshSessionElectronicInvoice,
  buildSendSessionElectronicInvoice,
} from '@features/sessions/session-electronic-invoice/api';
import { buildGetAppointmentSummaries } from '@features/appointment-summaries/filter-appointment-summaries/api';
import { buildGetSearchProducts } from '@features/product/add-product/api';
import { buildCreateQuote, buildSaveQuote } from '@features/quote/save-quote/api';
import { buildUpdateSalesOrderLines } from '@features/order/shared/order-lines/update-sales-order-lines/api';
import {
  buildGenerateAppointmentElectronicInvoice,
  buildRefreshAppointmentElectronicInvoice,
  buildSendAppointmentElectronicInvoice,
} from '@features/order/needs-assessment/appointment/appointment-electronic-invoice/api';
import { buildGetMicrosoftLink, buildRequestMicrosoftLogout } from '@features/microsoft-authorization/api';
import { buildSubmitNoShow } from '@features/order/needs-assessment/appointment/submit-no-show/api';
import { buildGetAssessorFreeSlots, buildSaveAssessorFreeSlots } from '@features/assessors/week-time-slots/api';
import { buildGetAssessorUtilisation } from '@features/assessor-utilisation/filter-assessor-utilisation-form/api';
import { buildGetAssessmentCentre, buildGetAssessmentCentresSummaries } from '@features/assessment-centres/api';
import { buildGetEquipmentInvoices } from '@features/equipment-invoicing/filter-equipment-invoice-form/api';
import { buildGenerateSalesOrderId } from '@features/order/shared/generate-sales-order-id/api';
import { buildGenerateInvoiceNumber } from '@features/order/shared/generate-invoice-number/api';
import { buildCreateOrResetAccount } from '@features/customer/create-or-reset-account/api';
import { buildGetAnnouncement } from '@features/announcement/api';
import { buildGetHep } from '@features/hep/api';
import { buildGetHeps } from '@features/hep/filter-heps-form/api';
import { buildGetAnnualSupportChargeList } from '@features/order/annual-support-charge/annual-support-charge-list/api';
import { buildGenerateAnnualSupportChargeByQuote } from '@features/order/annual-support-charge/api';
import {
  buildCreateAnnualSupportChargeManually,
  buildSaveAnnualSupportCharge,
} from '@features/order/annual-support-charge/save-annual-support-charge-form/api';
import { buildGetAppointmentInvoiceSummaries } from '@features/bulk-invoicing/appointment/filter-appointment-invoice-summaries/api';
import { buildGetQuickFilterTabs } from '@features/quick-filter-tabs/api';
import {
  buildBulkAddAppointmentSupplierInvoice,
  buildBulkGenerateAppointmentXeroInvoice,
  buildBulkSendAppointmentInvoice,
} from '@features/bulk-invoicing/appointment/api';
import { buildGetSessionInvoiceSummaries } from '@features/bulk-invoicing/session/filter-session-invoice-summaries/api';
import {
  buildBulkAddSessionSupplierInvoice,
  buildBulkGenerateSessionXeroInvoice,
  buildBulkSendSessionInvoice,
} from '@features/bulk-invoicing/session/api';
import { buildResumeAccount, buildSuspendAccount } from '@features/customer/suspend-or-resume-account/api';
import { buildCancelOrder, buildRestoreOrder } from '@features/order/shared/cancel-or-restore-order/api';
import { buildConnectToImmyBot, buildSetImmyBotInstallationLink } from '@features/immy-bot-link/api';

const reducerPath = 'api';
type ReducerPath = typeof reducerPath;

const tagTypes = [] as const;
type TagTypes = (typeof tagTypes)[number];

/**
 * Accepts two template types:
 * @template QueryArgumentType type of query argument
 * @template ResultType type of return
 * @param definition the only argument, that we usually pass to builder.query
 * @returns factory function, accepting builder and returning query definition we need for api slice
 */
export function appQueryFactory<QueryArgumentType, ResultType>(
  definition: OmitFromUnion<
    QueryDefinition<QueryArgumentType, AxiosBaseQuery, TagTypes, ResultType, ReducerPath>,
    'type'
  >,
) {
  return (
    builder: EndpointBuilder<AxiosBaseQuery, TagTypes, ReducerPath>,
  ): QueryDefinition<QueryArgumentType, AxiosBaseQuery, TagTypes, ResultType, ReducerPath> => builder.query(definition);
}

/**
 * Accepts two template types:
 * @template MutationArgumentType type of mutation argument
 * @template ResultType type of return
 * @param definition the only argument, that we usually pass to builder.mutation
 * @returns factory function, accepting builder and returning mutation definition we need for api slice
 */
export function appMutationFactory<MutationArgumentType, ResultType>(
  definition: OmitFromUnion<
    MutationDefinition<MutationArgumentType, AxiosBaseQuery, TagTypes, ResultType, ReducerPath>,
    'type'
  >,
) {
  return (
    builder: EndpointBuilder<AxiosBaseQuery, TagTypes, ReducerPath>,
  ): MutationDefinition<MutationArgumentType, AxiosBaseQuery, TagTypes, ResultType, ReducerPath> =>
    builder.mutation(definition);
}

export const apiSlice = createApi({
  reducerPath,
  tagTypes,
  baseQuery: axiosBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getOrderSummaries: buildGetOrderSummaries(builder),
    getAssessmentOrderSummaries: buildGetAssessmentOrderSummaries(builder),
    getDeliveryCalendar: buildGetDeliveryCalendar(builder),
    login: buildLogin(builder),
    verify: buildVerifyEmail(builder),
    confirmPassword: buildConfirmPassword(builder),
    getCustomer: buildGetCustomer(builder),
    saveCustomer: buildSaveCustomer(builder),
    createCustomer: buildCreateCustomer(builder),
    getOrder: buildGetOrder(builder),
    createOrder: buildCreateOrder(builder),
    saveOrder: buildSaveOrder(builder),
    getAllocations: buildGetAllocations(builder),
    getOrderAllocations: buildGetOrderAllocations(builder),
    createAllocation: buildCreateAllocation(builder),
    saveAllocation: buildSaveAllocation(builder),
    getModelProgressRecords: buildGetModelProgressRecords(builder),
    getOrderContactRecords: buildGetOrderContactRecords(builder),
    createOrderContactRecord: buildCreateOrderContactRecord(builder),
    getZohoLink: buildGetZohoLink(builder),
    requestZohoLogout: buildRequestZohoLogout(builder),
    getSessions: buildGetSessions(builder),
    createSession: buildCreateSession(builder),
    saveSession: buildSaveSession(builder),
    getAllocationSessions: buildGetAllocationSessions(builder),
    uploadTimesheet: buildUploadTimesheet(builder),
    getEmailTemplates: buildGetEmailTemplates(builder),
    getTutors: buildGetTutors(builder),
    getTutor: buildGetTutor(builder),
    getTutorFeedbackSummaries: buildGetTutorFeedbackSummaries(builder),
    getOrderTypeSummaries: buildGetOrderTypeSummaries(builder),
    getOrderTypeStatusSummaries: buildGetOrderTypeStatusSummaries(builder),
    getLabels: buildGetLabels(builder),
    getAssessors: buildGetAssessors(builder),
    getProducts: buildGetProducts(builder),
    getProduct: buildGetProduct(builder),
    getSensitivePersonalInformationPresence: buildGetSensitivePersonalInformationPresence(builder),
    requestSensitivePersonalInformation: buildRequestSensitivePersonalInformation(builder),
    getQuotes: buildGetQuotes(builder),
    getQuote: buildGetQuote(builder),
    getAppointments: buildGetAppointments(builder),
    createAppointment: buildCreateAppointment(builder),
    saveAppointment: buildSaveAppointment(builder),
    getReports: buildGetReports(builder),
    createReport: buildCreateReport(builder),
    saveReport: buildSaveReport(builder),
    refreshOrderElectronicInvoice: buildRefreshOrderElectronicInvoice(builder),
    sendOrderElectronicInvoice: buildSendOrderElectronicInvoice(builder),
    getAppointmentSummaries: buildGetAppointmentSummaries(builder),
    getSearchProducts: buildGetSearchProducts(builder),
    createQuote: buildCreateQuote(builder),
    saveQuote: buildSaveQuote(builder),
    updateSalesOrderLines: buildUpdateSalesOrderLines(builder),
    generateSessionElectronicInvoice: buildGenerateSessionElectronicInvoice(builder),
    refreshSessionElectronicInvoice: buildRefreshSessionElectronicInvoice(builder),
    sendSessionElectronicInvoice: buildSendSessionElectronicInvoice(builder),
    getFilteredAssessorsForOrderId: buildGetFilteredAssessorsForOrderId(builder),
    generateAppointmentElectronicInvoice: buildGenerateAppointmentElectronicInvoice(builder),
    refreshAppointmentElectronicInvoice: buildRefreshAppointmentElectronicInvoice(builder),
    sendAppointmentElectronicInvoice: buildSendAppointmentElectronicInvoice(builder),
    getAssessor: buildGetAssessor(builder),
    getAssessorFeedbackSummaries: buildGetAssessorFeedbackSummaries(builder),
    getZohoFreeSlots: buildGetZohoFreeSlots(builder),
    regenerateTimesheet: buildRegenerateTimesheet(builder),
    getMicrosoftLink: buildGetMicrosoftLink(builder),
    requestMicrosoftLogout: buildRequestMicrosoftLogout(builder),
    sendNeedsAssessmentReport: buildSendReport(builder),
    submitNoShow: buildSubmitNoShow(builder),
    getAssessorFreeSlots: buildGetAssessorFreeSlots(builder),
    saveAssessorFreeSlots: buildSaveAssessorFreeSlots(builder),
    getAssessorUtilisation: buildGetAssessorUtilisation(builder),
    saveProduct: buildSaveProduct(builder),
    getAssessmentCentres: buildGetAssessmentCentresSummaries(builder),
    getAssessmentCentre: buildGetAssessmentCentre(builder),
    convertToPdfReport: buildConvertToPdfReport(builder),
    getEquipmentInvoices: buildGetEquipmentInvoices(builder),
    saveSensitivePersonalInformation: buildSaveSensitivePersonalInformation(builder),
    generateSalesOrderId: buildGenerateSalesOrderId(builder),
    generateInvoiceNumber: buildGenerateInvoiceNumber(builder),
    createOrResetAccount: buildCreateOrResetAccount(builder),
    getAnnouncement: buildGetAnnouncement(builder),
    getHeps: buildGetHeps(builder),
    getHep: buildGetHep(builder),
    generateAnnualSupportChargeByQuote: buildGenerateAnnualSupportChargeByQuote(builder),
    getAnnualSupportChargeList: buildGetAnnualSupportChargeList(builder),
    createAnnualSupportChargeManually: buildCreateAnnualSupportChargeManually(builder),
    saveAnnualSupportCharge: buildSaveAnnualSupportCharge(builder),
    getAppointmentInvoiceSummaries: buildGetAppointmentInvoiceSummaries(builder),
    getQuickFilterTabs: buildGetQuickFilterTabs(builder),
    bulkAddAppointmentSupplierInvoice: buildBulkAddAppointmentSupplierInvoice(builder),
    bulkGenerateAppointmentXeroInvoice: buildBulkGenerateAppointmentXeroInvoice(builder),
    bulkSendAppointmentInvoice: buildBulkSendAppointmentInvoice(builder),
    getSessionInvoiceSummaries: buildGetSessionInvoiceSummaries(builder),
    bulkAddSessionSupplierInvoice: buildBulkAddSessionSupplierInvoice(builder),
    bulkGenerateSessionXeroInvoice: buildBulkGenerateSessionXeroInvoice(builder),
    bulkSendSessionInvoice: buildBulkSendSessionInvoice(builder),
    suspendAccount: buildSuspendAccount(builder),
    resumeAccount: buildResumeAccount(builder),
    cancelOrder: buildCancelOrder(builder),
    restoreOrder: buildRestoreOrder(builder),
    connectToImmyBot: buildConnectToImmyBot(builder),
    setImmyBotInstallationLink: buildSetImmyBotInstallationLink(builder),
  }),
});

apiSlice.reducerPath;

export const {
  useGetOrderSummariesQuery,
  useGetAssessmentOrderSummariesQuery,
  useGetDeliveryCalendarQuery,
  useGetCustomerQuery,
  useLoginMutation,
  useVerifyMutation,
  useConfirmPasswordMutation,
  useSaveCustomerMutation,
  useCreateCustomerMutation,
  useGetOrderQuery,
  useCreateOrderMutation,
  useSaveOrderMutation,
  useGetAllocationsQuery,
  useGetOrderAllocationsQuery,
  useCreateAllocationMutation,
  useSaveAllocationMutation,
  useGetModelProgressRecordsQuery,
  useGetOrderContactRecordsQuery,
  useCreateOrderContactRecordMutation,
  useGetSessionsQuery,
  useCreateSessionMutation,
  useSaveSessionMutation,
  useGetAllocationSessionsQuery,
  useGetZohoLinkQuery,
  useRequestZohoLogoutMutation,
  useUploadTimesheetMutation,
  useGetEmailTemplatesQuery,
  useGetTutorsQuery,
  useGetTutorQuery,
  useGetTutorFeedbackSummariesQuery,
  useGetOrderTypeSummariesQuery,
  useGetOrderTypeStatusSummariesQuery,
  useGetLabelsQuery,
  useGetAssessorsQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useGetSensitivePersonalInformationPresenceQuery,
  useRequestSensitivePersonalInformationMutation,
  useGetQuotesQuery,
  useGetQuoteQuery,
  useGetAppointmentsQuery,
  useCreateAppointmentMutation,
  useSaveAppointmentMutation,
  useGetReportsQuery,
  useCreateReportMutation,
  useSaveReportMutation,
  useGenerateSessionElectronicInvoiceMutation,
  useRefreshSessionElectronicInvoiceMutation,
  useSendSessionElectronicInvoiceMutation,
  useGetAppointmentSummariesQuery,
  useGetSearchProductsQuery,
  useCreateQuoteMutation,
  useSaveQuoteMutation,
  useUpdateSalesOrderLinesMutation,
  useRefreshOrderElectronicInvoiceMutation,
  useSendOrderElectronicInvoiceMutation,
  useGetFilteredAssessorsForOrderIdQuery,
  useGenerateAppointmentElectronicInvoiceMutation,
  useRefreshAppointmentElectronicInvoiceMutation,
  useSendAppointmentElectronicInvoiceMutation,
  useGetAssessorQuery,
  useGetAssessorFeedbackSummariesQuery,
  useGetZohoFreeSlotsQuery,
  useRegenerateTimesheetMutation,
  useGetMicrosoftLinkQuery,
  useRequestMicrosoftLogoutMutation,
  useSendNeedsAssessmentReportMutation,
  useSubmitNoShowMutation,
  useGetAssessorFreeSlotsQuery,
  useSaveAssessorFreeSlotsMutation,
  useGetAssessorUtilisationQuery,
  useSaveProductMutation,
  useGetAssessmentCentresQuery,
  useGetAssessmentCentreQuery,
  useConvertToPdfReportMutation,
  useGetEquipmentInvoicesQuery,
  useSaveSensitivePersonalInformationMutation,
  useGenerateSalesOrderIdMutation,
  useGenerateInvoiceNumberMutation,
  useCreateOrResetAccountMutation,
  useGetAnnouncementQuery,
  useGetHepsQuery,
  useGetHepQuery,
  useGenerateAnnualSupportChargeByQuoteMutation,
  useGetAnnualSupportChargeListQuery,
  useCreateAnnualSupportChargeManuallyMutation,
  useSaveAnnualSupportChargeMutation,
  useGetAppointmentInvoiceSummariesQuery,
  useGetQuickFilterTabsQuery,
  useBulkAddAppointmentSupplierInvoiceMutation,
  useBulkGenerateAppointmentXeroInvoiceMutation,
  useBulkSendAppointmentInvoiceMutation,
  useGetSessionInvoiceSummariesQuery,
  useBulkAddSessionSupplierInvoiceMutation,
  useBulkGenerateSessionXeroInvoiceMutation,
  useBulkSendSessionInvoiceMutation,
  useSuspendAccountMutation,
  useResumeAccountMutation,
  useCancelOrderMutation,
  useRestoreOrderMutation,
  useConnectToImmyBotMutation,
  useSetImmyBotInstallationLinkMutation,
} = apiSlice;
