import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';

export type FormModel = Pick<IOrderNeedsAssessment, 'disabilityTypeIds' | 'conditionIds'> & {
  medicalEvidenceFiles: File | FileList | null;
  preAssessmentFormFile: File | null;
  narFile: File | null;
  dateOfBirth: ISODateString;
  dsa1pdfFile: File | null;
};

export type ApiModel = Pick<IOrderNeedsAssessment, 'disabilityTypeIds' | 'conditionIds'> & {
  dsa1pdfFile: File | null;
  medicalEvidenceFiles: File | FileList | null;
  preAssessmentFormFile: File | null;
  narFile: File | null;
  dateOfBirth: ISODateString;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  disabilityTypeIds: { type: 'backend-label', optionKey: 'disabilityTypeOptions', multiple: true, initial: [] },
  conditionIds: { type: 'backend-label', optionKey: 'conditionOptions', multiple: true, initial: [] },
  dsa1pdfFile: { type: 'transportable', initial: null },
  medicalEvidenceFiles: { type: 'transportable', initial: null },
  preAssessmentFormFile: { type: 'transportable', initial: null },
  narFile: { type: 'transportable', initial: null },
  dateOfBirth: { type: 'date', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  disabilityTypeIds: identity,
  conditionIds: identity,
  dsa1pdfFile: identity,
  medicalEvidenceFiles: identity,
  preAssessmentFormFile: identity,
  dateOfBirth: toISODay,
  narFile: identity,
});
