import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { createColumnFactory } from '@utils/create-column.factory';
import { useReports } from './use-reports';
import { IReport } from './report.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { formatDateTime } from '@utils/dates/format-date-time';
import { renderEnum } from '@utils/render-enum';
import { UserRoleOptions, UserRoles } from '@features/user/user-role.type';
import { BooleanChip } from '@utils/render-boolean';
import { AppModal } from '@components/AppModal';
import { SaveReportForm } from './save-report/SaveReportForm';
import { OpenFile } from '@components/inputs/OpenFile';
import DownloadIcon from '@mui/icons-material/Download';
import { useAppSelector } from '@store/use-app-selector';
import { downloadReportTemplate } from './download-report-template/download-report-template';
import { SendReport } from './send-report/SendReport';
import { RestrictRole } from '@features/auth/RestrictRole';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';
import { useToggle } from '@hooks/use-toggle';
import { useConvertToPdf } from './use-convert-to-pdf';
import { PdfRegexp } from '@utils/pdf-regexp';
import { WhyDisabled } from '@components/WhyDisabled';
import { AppDrawer } from '@components/AppDrawer';

type Props = {
  order: IOrderNeedsAssessment;
};

const createReportHistoryColumn = createColumnFactory<IReport>();

const defaultSortModel: GridSortModel = [
  {
    field: 'uploadDate',
    sort: 'desc',
  },
];

export const Reports: React.FC<Props> = ({ order }) => {
  const { reports, isLoading } = useReports(order.id);
  const { convertToPdfHandle } = useConvertToPdf();
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [editId, setEditId] = useState<number | null>(null);
  const token = useAppSelector((state) => state.user.token);

  const closeModal = useCallback((): void => {
    toggleIsModalOpen();
    setEditId(null);
  }, [toggleIsModalOpen]);

  const handleEditReport = useCallback(
    (id: number) => {
      setEditId(id);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const openDrawer = useCallback(
    (id: number): void => {
      setEditId(id);
      toggleIsDrawerOpen();
    },
    [toggleIsDrawerOpen],
  );

  const closeDrawer = useCallback((): void => {
    toggleIsDrawerOpen();
    setEditId(null);
  }, [toggleIsDrawerOpen]);

  const columns: GridColDef[] = useMemo(
    () => [
      createReportHistoryColumn('id', { headerName: 'Id', width: 50 }),
      createReportHistoryColumn('uploadDate', {
        headerName: 'Upload Date Time',
        width: 160,
        renderCell: ({ row }) => formatDateTime(row.uploadDate),
      }),
      createReportHistoryColumn('reportReferenceFilePath', {
        headerName: 'Report',
        flex: 1,
        renderCell: ({ row }) => (
          <OpenFile path={row.reportReferenceFilePath} isPreview>
            <Link>{row.reportReferenceFileName ?? 'Open Report'}</Link>
          </OpenFile>
        ),
      }),
      createReportHistoryColumn('isCurrent', {
        headerName: 'Current',
        width: 90,
        renderCell: ({ row }) => <BooleanChip value={row.isCurrent} />,
      }),
      createReportHistoryColumn('role', {
        headerName: 'Role',
        width: 160,
        renderCell: ({ row }) => renderEnum(row.role, UserRoleOptions),
      }),
      createReportHistoryColumn('userId', {
        headerName: 'User',
        width: 160,
        renderCell: ({ row }) => <BackendLabel value={row.userId} optionKey="userOptions" />,
      }),
      createReportHistoryColumn('isFinal', {
        headerName: 'Final',
        width: 90,
        renderCell: ({ row }) => <BooleanChip value={row.isFinal} />,
      }),

      createReportHistoryColumn('action', {
        headerName: 'Action',
        flex: 1,
        renderCell: ({ row }) => {
          const isPdf = PdfRegexp.test(row.reportReferenceFileName);
          const isConvertToPdfDisabled = !row.isFinal || isPdf;
          const isSendReportDisabled = !isPdf || !row.isFinal;

          return (
            <Stack direction="row" spacing={1}>
              <WhyDisabled
                title="Report is not marked as final and file should be docx format"
                disabled={isConvertToPdfDisabled}
              >
                <Button
                  onClick={(): Promise<void> => convertToPdfHandle(row.orderId, row.id)}
                  variant="outlined"
                  size="small"
                  disabled={isConvertToPdfDisabled}
                >
                  Convert to PDF
                </Button>
              </WhyDisabled>

              <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
                <WhyDisabled title="Report is not marked as final" disabled={isSendReportDisabled}>
                  <Button
                    onClick={(): void => openDrawer(row.id)}
                    variant="outlined"
                    size="small"
                    disabled={isSendReportDisabled}
                  >
                    Send
                  </Button>
                </WhyDisabled>
              </RestrictRole>
              <Button onClick={(): void => handleEditReport(row.id)} variant="outlined" size="small">
                Edit
              </Button>
            </Stack>
          );
        },
      }),
    ],
    [handleEditReport, openDrawer, convertToPdfHandle],
  );

  const activeReport = useMemo(() => reports.find((report) => report.id === editId), [editId, reports]);

  const modalTitle = editId === null ? 'New Report' : 'Edit Report';

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleDownloadClick = (): void => {
    if (token) {
      downloadReportTemplate(order.id, token);
    }
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }} spacing={1}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Report History
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={(): void => handleDownloadClick()}
          startIcon={<DownloadIcon />}
        >
          Download Report Template
        </Button>

        <Button variant="outlined" size="small" onClick={toggleIsModalOpen} startIcon={<AddIcon />}>
          Create Report
        </Button>
      </Stack>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={reports}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
      />
      <AppDrawer open={isDrawerOpen} onClose={closeDrawer}>
        <SendReport report={activeReport} order={order} />
      </AppDrawer>
      <AppModal open={isModalOpen} onClose={closeModal} title={modalTitle}>
        <SaveReportForm report={activeReport} orderId={order.id} onClose={closeModal} />
      </AppModal>
    </Box>
  );
};
