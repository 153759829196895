import { Describe, any, array, boolean, nullable, number, object, string } from 'superstruct';
import { ICustomer } from '../customer.interface';

export const CustomerStruct: Describe<ICustomer> = object({
  address: nullable(string()),
  altNumber: nullable(string()),
  contactNumber: nullable(string()),
  crn: nullable(string()),
  email: string(),
  firstName: string(),
  fundingBodyId: nullable(number()),
  hepId: nullable(number()),
  id: number(),
  lastName: string(),
  notes: nullable(string()),
  postcode: nullable(string()),
  prefCommunicationWay: array(number()),
  hasAccount: boolean(),
  extraMile: boolean(),
  ordersTabs: array(any()),
  isAccountSuspended: boolean(),
  externalImmyBotId: nullable(number()),
});
