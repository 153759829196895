import { emptyStringToNull } from '@utils/empty-string-to-null';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';

export type FormModel = {
  name: string;
  city: string;
  assessorId: number[];
  studentsPostcode: string;
};

export type ApiModel = {
  name: string | null;
  city: string | null;
  assessorId: number[];
  studentsPostcode: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  name: { type: 'transportable', initial: '' },
  city: { type: 'transportable', initial: '' },
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  studentsPostcode: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  name: emptyStringToNull,
  city: emptyStringToNull,
  assessorId: nullToString,
  studentsPostcode: emptyStringToNull,
});
