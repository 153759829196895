import React from 'react';
import moment from 'moment-timezone';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDateTimePicker } from '@components/inputs/ControlledDateTimePicker';
import { ISession } from '@features/sessions/session.interface';
import { useSaveSessionForm } from './use-save-session-form';
import { ISODateString } from '@utils/dates/iso-string.type';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';

type Props = {
  session: ISession | void;
  allocationId: number;
  endDate: ISODateString | null;
} & ModalChildProps;

export const SaveSessionForm: React.FC<Props> = ({ session, allocationId, endDate, onClose }) => {
  const {
    submitHandler,
    isSaveEnabled,
    buttonText,
    control,
    watch,
    sessionsCancellationReasonOptions,
    isLabelsLoading,
  } = useSaveSessionForm(session, allocationId, onClose);

  const endDateMoment = moment(endDate);

  const isCancelled = watch('isCancelled');

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledDateTimePicker
          control={control}
          name="startMoment"
          label="Start Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
        />
        <ControlledTextField control={control} name="duration" label="Duration" inputProps={{ inputMode: 'numeric' }} />
        <ControlledTextField control={control} name="tutorInvoice" label="Tutor Invoice" />
        <ControlledTextField control={control} name="funderInvoice" label="Funder Invoice" />
        <ControlledDateTimePicker
          control={control}
          name="offeredDate"
          label="Offered Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
        />
        <ControlledToggleButton
          control={control}
          name="isCancelled"
          getLabel={(value: boolean): string => (value ? 'Cancelled' : 'Not Cancelled')}
        />
        {isCancelled && (
          <ControlledDateTimePicker
            control={control}
            name="cancellationDate"
            label="Cancellation Date Time"
            minutesStep={5}
          />
        )}
        {isCancelled && (
          <ControlledAutocomplete
            control={control}
            name="cancellationReason"
            options={sessionsCancellationReasonOptions}
            label="Cancellation Reason"
            isLoading={isLabelsLoading}
          />
        )}
        <Button variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  );
};
