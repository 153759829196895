import { useGetZohoFreeSlotsQuery } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useEffect, useState } from 'react';
import { GroupedByDay, groupSlotsByDay } from './group-slots-by-date';
import { ZohoSlotsQuery } from './api';
import { splitSlotsByDuration } from './split-slots-by-duration';
import { splitSlotsByTimeIntervals } from './split-slots-by-time-intervals';
import moment, { Moment } from 'moment-timezone';
import { getMonthRange } from './get-month-range';
import { ISODateTimeString } from '@utils/dates/iso-string.type';

type HookResult = RequestStatusFlags & {
  groupedByDay: GroupedByDay;
  offeredDate: ISODateTimeString | null;
  handleMonthChange: React.Dispatch<React.SetStateAction<Moment>>;
};

/**
 * @param {number} duration in hours
 */
export function useZohoFreeSlots(params: ZohoSlotsQuery, duration: number, isASThardcoded = false): HookResult {
  const [fromMoment, setFromMoment] = useState<Moment>(moment());
  const dateRange = getMonthRange(fromMoment);
  const shouldSkip = moment(dateRange.to).isBefore(moment());
  const { data, ...flags } = useGetZohoFreeSlotsQuery(
    { ...params, ...dateRange },
    {
      refetchOnMountOrArgChange: true,
      skip: shouldSkip,
    },
  );
  const [groupedByDay, setGroupedByDay] = useState<GroupedByDay>({});
  const [date, setDate] = useState<ISODateTimeString | null>(null);

  useEffect(() => {
    if (data && !isErrorResponse(data)) {
      // actually all this things (grouping and splitting) is backend work,
      // but unfortunately they can't do this right now
      const { freeSlots, offeredDate } = data;
      const splitted = isASThardcoded
        ? splitSlotsByTimeIntervals(freeSlots)
        : splitSlotsByDuration(freeSlots, duration);
      setGroupedByDay(groupSlotsByDay(splitted));
      setDate(offeredDate ?? null);
    }

    return () => {
      setGroupedByDay({});
    };
  }, [data, duration, isASThardcoded]);

  return { groupedByDay, offeredDate: date, handleMonthChange: setFromMoment, ...flags };
}
