import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';

export type FormModel = {
  assessorId: number[];
  employmentType: number[];
  organisationId: number[];
};

export type ApiModel = {
  assessorId: number[];
  employmentType: number[];
  organisationId: number[];
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  employmentType: { type: 'backend-label', optionKey: 'employmentOptions', multiple: true, initial: [] },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  assessorId: nullToString,
  employmentType: nullToString,
  organisationId: nullToString,
});
