import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  name: string;
};

export type ApiModel = {
  name: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  name: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  name: emptyStringToNull,
});
