import { TransportableModel } from '@models/transportable.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TransformFunction<T> = (value: any) => T;

type TransformMap<ApiModel extends TransportableModel<ApiModel>> = {
  [K in keyof ApiModel]: TransformFunction<ApiModel[K]>;
};

/**
 * Helper factory accepting transformation map, describing how to serialize each field in given `ApiModel` template type.
 * The return type of transform function should match type of `ApiModel` field with same key.
 * @returns {(model: FormModel) => ApiModel} function accepting FormModel model and returning valid `ApiModel` model
 */
export function serializeFactory<
  ApiModel extends TransportableModel<ApiModel>,
  FormModel extends Record<string, unknown>,
>(transformMap: TransformMap<ApiModel>) {
  return (model: FormModel): ApiModel => {
    const output: Partial<ApiModel> = {};

    for (const key in transformMap) {
      const transform = transformMap[key];
      output[key] = transform(model[key]);
    }

    return output as ApiModel;
  };
}
