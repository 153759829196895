import React, { PropsWithChildren } from 'react';
import { Drawer, DrawerProps } from '@mui/material';

export type DrawerChildProps = {
  width?: string;
};

type Props = PropsWithChildren<DrawerChildProps> & DrawerProps;

export const AppDrawer: React.FC<Props> = ({ children, width = '72vw', anchor = 'right', ...drawerProps }) => {
  return (
    <Drawer {...drawerProps} anchor={anchor} PaperProps={{ sx: { width, height: '100%', padding: 2 } }}>
      {children}
    </Drawer>
  );
};
