import { Moment } from 'moment-timezone';
import { identity } from 'lodash';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { emptyStringToNull } from '@utils/empty-string-to-null';
import { toISODay } from '@utils/dates/to-iso-day';
import { QuoteStatusOptions, QuoteStatusType } from '../quote-status.type';
import { ISODateString } from '@utils/dates/iso-string.type';

export type FormModel = {
  quoteId: string;
  status: QuoteStatusType[];
  crn: string;
  dateGeneratedFrom: Moment | null;
  dateGeneratedTo: Moment | null;
  exVatFrom: string;
  exVatTo: string;
};

export type ApiModel = {
  quoteId: string | null;
  status: QuoteStatusType[];
  crn: string | null;
  dateGeneratedFrom: ISODateString;
  dateGeneratedTo: ISODateString;
  exVatFrom: number | null;
  exVatTo: number | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  quoteId: { type: 'transportable', initial: '' },
  status: { type: 'enum', options: QuoteStatusOptions, multiple: true, initial: [] },
  crn: { type: 'transportable', initial: '' },
  dateGeneratedFrom: { type: 'date', initial: null },
  dateGeneratedTo: { type: 'date', initial: null },
  exVatFrom: { type: 'transportable', initial: '' },
  exVatTo: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  quoteId: emptyStringToNull,
  status: identity,
  crn: emptyStringToNull,
  dateGeneratedFrom: toISODay,
  dateGeneratedTo: toISODay,
  exVatFrom: (value: string) => (value === '' ? null : parseFloat(value)),
  exVatTo: (value: string) => (value === '' ? null : parseFloat(value)),
});
