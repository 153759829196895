import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { BooleanOptions } from '@utils/boolean-options';

export const FilterOrderSummariesForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels([
    'typeOptions',
    'statusOptions',
    'organisationOptions',
    'labelOptions',
    'orderTypeNameOptions',
  ]);
  const [params, setParams] = useSearchParams();

  const { control, handleSubmit, reset, watch } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  const orderTypes = watch('orderTypeId');
  const statusForChosenTypes = backendLabels.statusOptions.filter(
    (option) => orderTypes.length === 0 || orderTypes.some((orderType) => option.orderTypeId === orderType),
  );

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="orderTypeId"
            label="Order Type"
            options={backendLabels.orderTypeNameOptions}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="orderStatusId"
            label="Order Status"
            options={statusForChosenTypes}
            isLoading={isLoading}
            multiple
            isStatus
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="organisationId"
            label="Organisation"
            options={backendLabels.organisationOptions}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="labels"
            label="Labels"
            options={backendLabels.labelOptions}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="customerFirstName" label="First Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="customerLastName" label="Last Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="customerCrn" label="CRN" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="customerEmail" label="Email" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="customerContactNumber" label="Phone" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete control={control} name="isCancelled" label="Cancelled" options={BooleanOptions} />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
