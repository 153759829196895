import { Moment } from 'moment-timezone';
import { identity } from 'lodash';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { emptyStringToNull } from '@utils/empty-string-to-null';
import { AssessmentMethodOptions, AssessmentMethodType } from '@features/order/needs-assessment/assessment-method.type';
import { toBooleanOrNull } from '@utils/to-boolean-or-null';

export type FormModel = {
  startDate: Moment | null;
  endDate: Moment | null;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: boolean | null;
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
  customerEmail: string;
  assessmentCentreId: Array<number | null>;
  orderStatusId: Array<number | null>;
  appointmentId: number | null;
  assessmentMethod: AssessmentMethodType | null;
  assessmentType: Array<string | null>;
  isCancelled: boolean | null;
};

export type ApiModel = {
  startDate: ISODateString;
  endDate: ISODateString;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: boolean | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
  customerEmail: string | null;
  assessmentCentreId: Array<number | null>;
  orderStatusId: Array<number | null>;
  appointmentId: number | null;
  assessmentMethod: AssessmentMethodType | null;
  assessmentType: Array<string | null>;
  isCancelled: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startDate: { type: 'date', initial: null },
  endDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  isPaid: { type: 'transportable', initial: null },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
  customerEmail: { type: 'transportable', initial: '' },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  appointmentId: { type: 'transportable', initial: null },
  assessmentMethod: { type: 'enum', options: AssessmentMethodOptions, initial: null },
  assessmentType: { type: 'backend-label', optionKey: 'assessmentTypeOptions', multiple: true, initial: [] },
  isCancelled: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startDate: toISODay,
  endDate: toISODay,
  organisationId: nullToString,
  assessorId: nullToString,
  funderInvoice: emptyStringToNull,
  supplierInvoice: emptyStringToNull,
  isPaid: toBooleanOrNull,
  customerFirstName: emptyStringToNull,
  customerLastName: emptyStringToNull,
  customerCrn: emptyStringToNull,
  customerEmail: emptyStringToNull,
  assessmentCentreId: nullToString,
  orderStatusId: nullToString,
  appointmentId: identity,
  assessmentMethod: identity,
  assessmentType: nullToString,
  isCancelled: toBooleanOrNull,
});
