import { useCreateSessionMutation, useSaveSessionMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { ISession } from '@features/sessions/session.interface';
import { SessionsActions } from '@features/sessions/sessions.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { FormModel, sanitize, serialize } from './form-config';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveEnabled: boolean;
  isLabelsLoading: boolean;
  buttonText: string;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveSessionForm(session: ISession | void, allocationId: number, onClose: () => void): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels(['sessionsCancellationReasonOptions']);
  const defaultValues = sanitize(session, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError } = useFormResult;
  const [createMutation, createFlags] = useCreateSessionMutation();
  const [saveMutation, saveFlags] = useSaveSessionMutation();
  const dispatch = useAppDispatch();

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const id = session?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<ISession | void> => {
      const serializedData = serialize(data);

      const mutationHandler = id ? saveMutation : createMutation;
      const payload = {
        allocationId,
        payload: {
          ...serializedData,
          id,
        },
      };
      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(SessionsActions.upsertSession(result));
      onClose();
    },
    [id, saveMutation, createMutation, allocationId, dispatch, onClose, errorHandler],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    isSaveEnabled: hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLabelsLoading,
    isLabelsLoading,
    buttonText: id ? 'Save Session' : 'Create Session',
  };
}
