import { identity } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { IAllocation } from '@features/allocations/allocation.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { toISODay } from '@utils/dates/to-iso-day';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<IAllocation, 'nmhSupportType' | 'hourlyRate' | 'allocated' | 'tutorId' | 'isValidated'> & {
  endDate: Moment | null;
};

export type ApiModel = Pick<
  IAllocation,
  'nmhSupportType' | 'hourlyRate' | 'allocated' | 'tutorId' | 'isValidated' | 'endDate'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  nmhSupportType: { type: 'backend-label', optionKey: 'supportOptions', initial: null },
  hourlyRate: { type: 'transportable', initial: 0 },
  allocated: { type: 'transportable', initial: 0 },
  endDate: { type: 'date', initial: moment() },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', initial: null },
  isValidated: { type: 'transportable', initial: false },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  nmhSupportType: identity,
  hourlyRate: parseInt,
  allocated: parseFloat,
  tutorId: identity,
  isValidated: toBoolean,
  endDate: toISODay,
});
