import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { emptyStringToNull } from '@utils/empty-string-to-null';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { Moment } from 'moment-timezone';

export type FormModel = {
  pickupDateFrom: Moment | null;
  pickupDateTo: Moment | null;
  organisationId: Array<number | null>;
  dipReference: string;
};

export type ApiModel = {
  pickupDateFrom: ISODateString | null;
  pickupDateTo: ISODateString | null;
  organisationId: Array<number | null>;
  dipReference: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  pickupDateFrom: { type: 'date', initial: null },
  pickupDateTo: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  dipReference: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  pickupDateFrom: toISODay,
  pickupDateTo: toISODay,
  organisationId: nullToString,
  dipReference: emptyStringToNull,
});
