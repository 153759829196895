import { IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { EmptyCell } from './empty-cell';

export function renderEnum(
  value: string | number | null,
  options: IBackendLabelOption<string | number | null>[],
): string {
  if (value === null) {
    return EmptyCell;
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : value.toString();
}
