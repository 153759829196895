import { sanitizeFactory } from '@utils/sanitize.factory';
import { IProductWithCondition } from '../product.interface';
import { identity } from 'lodash';
import { serializeFactory } from '@utils/serialize.factory';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<
  IProductWithCondition,
  | 'productName'
  | 'productCodeAs'
  | 'productCodeSst'
  | 'isActive'
  | 'amountExVat'
  | 'vatTreatment'
  | 'trafficLight'
  | 'productDescription'
>;

export type ApiModel = Pick<
  IProductWithCondition,
  | 'productName'
  | 'productCodeAs'
  | 'productCodeSst'
  | 'isActive'
  | 'amountExVat'
  | 'vatTreatment'
  | 'trafficLight'
  | 'productDescription'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  productName: { type: 'transportable', initial: '' },
  productCodeAs: { type: 'transportable', initial: '' },
  productCodeSst: { type: 'transportable', initial: '' },
  isActive: { type: 'transportable', initial: false },
  amountExVat: { type: 'transportable', initial: 0 },
  vatTreatment: { type: 'transportable', initial: '' },
  trafficLight: { type: 'backend-label', optionKey: 'productTrafficLightOptions', initial: null },
  productDescription: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  productName: identity,
  productCodeAs: identity,
  productCodeSst: identity,
  isActive: toBoolean,
  amountExVat: parseFloat,
  vatTreatment: identity,
  trafficLight: identity,
  productDescription: identity,
});
