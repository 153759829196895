// This is an autogenerated file during deploy.sh
// Please do not change it manually
// We need common js module for using with webpack
// So here we use js file with ts typings
module.exports = {
  baseUrl: 'https://api.assistivedsa.com',
  title: 'Assistive DSA CRM',
  color: '#0288d1',
  isWarning: false,
};
