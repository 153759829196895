import { Describe, enums, nullable, number, object, pattern, string } from 'superstruct';
import { IActionRecord } from '../action-record.interface';
import { ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { Ways } from '../way.type';
import { RecordTypes } from '../record-type.type';

export const ActionRecordStruct: Describe<IActionRecord> = object({
  id: number(),
  way: nullable(enums(Object.values(Ways))),
  recordType: nullable(enums(Object.values(RecordTypes))),
  createdAt: pattern(string(), ISODateTimeRegexp),
  details: string(),
  userId: nullable(number()),
  trackableId: nullable(number()),
});
