import { Moment } from 'moment-timezone';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { emptyStringToNull } from '@utils/empty-string-to-null';
import { toBooleanOrNull } from '@utils/to-boolean-or-null';

export type FormModel = {
  sessionId: string;
  startMomentFrom: Moment | null;
  startMomentTo: Moment | null;
  organisationId: Array<number | null>;
  tutorId: Array<number | null>;
  qualification: Array<number | null>;
  orderStatusId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isCancelled: boolean;
  isPaid: boolean;
  hasTimesheet: boolean;
  dipReference: string | null;
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
  isUninvoicableHidden: boolean;
};

export type ApiModel = {
  sessionId: string | null;
  startMomentFrom: ISODateString;
  startMomentTo: ISODateString;
  organisationId: Array<number | null>;
  tutorId: Array<number | null>;
  qualification: Array<number | null>;
  orderStatusId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isCancelled: boolean | null;
  isPaid: boolean | null;
  hasTimesheet: boolean | null;
  dipReference: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
  isUninvoicableHidden: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  sessionId: { type: 'transportable', initial: '' },
  startMomentFrom: { type: 'date', initial: null },
  startMomentTo: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', multiple: true, initial: [] },
  qualification: { type: 'backend-label', optionKey: 'qualificationOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: null },
  isPaid: { type: 'transportable', initial: null },
  hasTimesheet: { type: 'transportable', initial: null },
  dipReference: { type: 'transportable', initial: '' },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
  isUninvoicableHidden: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  sessionId: emptyStringToNull,
  startMomentFrom: toISODay,
  startMomentTo: toISODay,
  organisationId: nullToString,
  tutorId: nullToString,
  qualification: nullToString,
  orderStatusId: nullToString,
  funderInvoice: emptyStringToNull,
  supplierInvoice: emptyStringToNull,
  isPaid: toBooleanOrNull,
  isCancelled: toBooleanOrNull,
  hasTimesheet: toBooleanOrNull,
  dipReference: emptyStringToNull,
  customerFirstName: emptyStringToNull,
  customerLastName: emptyStringToNull,
  customerCrn: emptyStringToNull,
  isUninvoicableHidden: toBooleanOrNull,
});
