import React, { FC } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { useSaveOrderForm } from '../use-save-order-form';
import { sanitize, serialize } from './form.config';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { useGenerateAnnualSupportChargeByQuote } from './use-generate-annual-support-charge';
import { IOrderAnnualSupportCharge } from './annual-support-charge-order.interface';
import { AnnualSupportChargeList } from './annual-support-charge-list/AnnualSupportChargeList';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';

type Props = {
  order: IOrderAnnualSupportCharge;
};

export const OrderAnnualSupportCharge: FC<Props> = ({ order }) => {
  const { control, submitHandler, isButtonDisabled, operatorOptions, labelOptions, isDropdownsLoading, statusOptions } =
    useSaveOrderForm(order, sanitize, serialize);

  const { generateAnnualSupportChargeByQuote, isLoading } = useGenerateAnnualSupportChargeByQuote();

  async function handleGenerateAnnualSupportChargeByQuote(): Promise<void> {
    generateAnnualSupportChargeByQuote(order.id);
  }

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="userId"
              label="Operator"
              options={operatorOptions}
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="labels"
              label="Order Labels"
              options={labelOptions}
              isLoading={isDropdownsLoading}
              multiple
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="orderStatusId"
              options={statusOptions}
              label="Order Status"
              isLoading={isDropdownsLoading}
              isStatus
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledFileField
              fullWidth
              control={control}
              name="acceptanceLetterFile"
              label="Upload Acceptance"
              fileDetails={order.acceptanceFilePath}
              buttonLabel="Preview Acceptance"
              inputProps={{
                accept: '.pdf',
              }}
              isPreview
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledTextField control={control} name="quoteInternalId" label="Quote ID" />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <AppLoadingButton
              type="submit"
              variant="outlined"
              fullWidth
              onClick={handleGenerateAnnualSupportChargeByQuote}
              disabled={!order.canGenerateAsc}
              isLoading={isLoading}
            >
              Add Asc
            </AppLoadingButton>
          </Grid>
        </Grid>
        <Box>
          <AnnualSupportChargeList order={order} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
        </Box>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <CancelOrRestoreOrder orderId={order.id} />
          <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
            Save Order
          </Button>
        </Stack>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
    </Box>
  );
};
