import React, { FunctionComponent } from 'react';
import {
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IOrderTypeStatusSummary } from './order-type-status-summary.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { Routes } from '@routes/routes';
import { formatPercent } from '@utils/format-percent';

type Props = IOrderTypeStatusSummary & {
  isLoading: boolean;
};

export const StatusTable: FunctionComponent<Props> = ({ type, summaries, isLoading }) => {
  return (
    <TableContainer component={Paper} sx={{ p: 3, boxSizing: 'border-box', height: '100%' }}>
      <Typography variant="h4" component="h1">
        <BackendLabel value={type} optionKey="orderTypeNameOptions" isPlainText fallbackText="New Order" />
      </Typography>
      <Table sx={{ width: '100%', mt: 1 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right" width="80">
              Active
            </TableCell>
            <TableCell align="right" width="100">
              Average Delay
            </TableCell>
            <TableCell align="right" width="100">
              Dropped Off
            </TableCell>
            <TableCell align="right" width="120">
              Drop Percentage
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            summaries.map((summary) => (
              <TableRow key={summary.status} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Link
                    href={`${Routes.orderSummaries}?orderTypeId=${type}&orderStatusId=${summary.status}`}
                    sx={{ display: 'block' }}
                  >
                    <BackendLabel
                      value={summary.status}
                      optionKey="statusOptions"
                      isPlainText
                      fallbackText="No Status"
                    />
                  </Link>
                </TableCell>
                <TableCell align="right" className="monospace">
                  {summary.activeCount}
                </TableCell>
                <TableCell align="right" className="monospace">
                  {summary.averageDelay}
                </TableCell>
                <TableCell align="right" className="monospace">
                  {summary.dropOffCount}
                </TableCell>
                <TableCell align="right" className="monospace">
                  {formatPercent(summary.dropOffPercent)}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
