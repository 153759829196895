import { identity } from 'lodash';
import { Moment } from 'moment-timezone';
import { ISession } from '@features/sessions/session.interface';
import { toISODateTimeFlooredToMinutes } from '@utils/dates/to-iso-date-time-floored-to-minutes';
import { serializeFactory } from '@utils/serialize.factory';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<
  ISession,
  | 'duration'
  | 'tutorInvoice'
  | 'funderInvoice'
  | 'isCancelled'
  | 'cancellationReason'
  | 'cancellationDate'
  | 'offeredDate'
> & {
  startMoment: Moment | null;
};

export type ApiModel = Pick<
  ISession,
  | 'startMoment'
  | 'duration'
  | 'tutorInvoice'
  | 'funderInvoice'
  | 'isCancelled'
  | 'cancellationReason'
  | 'cancellationDate'
  | 'offeredDate'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startMoment: { type: 'date-time', initial: null },
  duration: { type: 'transportable', initial: '' },
  tutorInvoice: { type: 'transportable', initial: '' },
  funderInvoice: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: false },
  cancellationReason: { type: 'backend-label', optionKey: 'sessionsCancellationReasonOptions', initial: null },
  cancellationDate: { type: 'date-time', initial: null },
  offeredDate: { type: 'date-time', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startMoment: toISODateTimeFlooredToMinutes,
  duration: parseFloat,
  tutorInvoice: identity,
  funderInvoice: identity,
  isCancelled: toBoolean,
  cancellationDate: toISODateTimeFlooredToMinutes,
  cancellationReason: identity,
  offeredDate: toISODateTimeFlooredToMinutes,
});
