import { createEnumOptions } from '@utils/create-enum.factory';

export const DateFilter = {
  Today: 'today',
  Tomorrow: 'tomorrow',
} as const;

type Keys = keyof typeof DateFilter;

export type DateFilterType = (typeof DateFilter)[Keys];

export const DateFilterOptions = createEnumOptions(DateFilter);
