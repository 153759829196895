import { IOrderTypeStatusSummary } from '../order-type-status-summary.interface';
import { Describe, StructError, array, assert, nullable, number, object } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';

const IOrderTypeStatusSummaryStruct: Describe<IOrderTypeStatusSummary> = object({
  type: nullable(number()),
  summaries: array(
    object({
      status: nullable(number()),
      activeCount: number(),
      averageDelay: nullable(number()),
      dropOffCount: number(),
      dropOffPercent: number(),
    }),
  ),
});

export function validateOrderTypeStatusSummary(response: IOrderTypeStatusSummary[], url: string): void {
  try {
    for (const summary of response) {
      assert(summary, IOrderTypeStatusSummaryStruct);
    }
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
