import { findClosestWeekends } from '@utils/dates/find-closest-weekends';
import { Moment } from 'moment-timezone';

const SecondsInDay = 60 * 60 * 24;

export function getIsInvoiceDisabled(
  appointmentDateMoment: Moment | null,
  cancellationDateMoment: Moment | null,
): boolean {
  if (!appointmentDateMoment || !cancellationDateMoment) {
    return false;
  }

  if (appointmentDateMoment.isBefore(cancellationDateMoment)) {
    return false;
  }

  const appointmentDiffSeconds = appointmentDateMoment.diff(cancellationDateMoment, 'seconds');
  // even if there are weekend in between dates, if diff more that 3 days, there are 1 working day for sure
  if (appointmentDiffSeconds > SecondsInDay * 3) {
    return true;
  } else if (appointmentDiffSeconds <= SecondsInDay) {
    return false;
  }

  const { saturdayStart, mondayStart } = findClosestWeekends(cancellationDateMoment, appointmentDateMoment);
  // no weekend in date range but more that 1 working day
  if (!saturdayStart && !mondayStart && appointmentDiffSeconds > SecondsInDay) {
    return true;
  }

  const weekendStart = saturdayStart ?? cancellationDateMoment;
  const weekendEnd = mondayStart ?? appointmentDateMoment;

  const closestWeekendSeconds = weekendEnd.diff(weekendStart, 'seconds');

  return appointmentDiffSeconds - closestWeekendSeconds > SecondsInDay;
}
