import { identity } from 'lodash';
import { IReport } from '../report.interface';
import { serializeFactory } from '@utils/serialize.factory';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<IReport, 'isCurrent' | 'isFinal'> & {
  reportReferenceFile: File | null;
};

export type ApiModel = Pick<IReport, 'isCurrent' | 'isFinal'> & {
  reportReferenceFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  isCurrent: { type: 'transportable', initial: false },
  reportReferenceFile: { type: 'transportable', initial: null },
  isFinal: { type: 'transportable', initial: false },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  isCurrent: identity,
  reportReferenceFile: identity,
  isFinal: toBoolean,
});
