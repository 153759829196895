import { identity } from 'lodash';
import { IOrderUnsupportedCustomer } from './order-unsupported-customer.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';

export type FormModel = Pick<
  IOrderUnsupportedCustomer,
  'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes' | 'quoteId' | 'funderInvoice'
> & {
  acceptanceLetterFile: File | null;
};

export type ApiModel = Pick<
  IOrderUnsupportedCustomer,
  'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes' | 'quoteId' | 'funderInvoice'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', initial: null },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  quoteId: { type: 'transportable', initial: '' },
  funderInvoice: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: identity,
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  quoteId: identity,
  funderInvoice: identity,
  acceptanceLetterFile: identity,
});
