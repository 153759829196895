import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { IOrderAnnualSupportCharge } from './annual-support-charge-order.interface';

export type FormModel = Pick<
  IOrderAnnualSupportCharge,
  'orderStatusId' | 'userId' | 'labels' | 'quoteInternalId' | 'notes'
> & {
  acceptanceLetterFile: File | null;
};

export type ApiModel = Pick<
  IOrderAnnualSupportCharge,
  'orderStatusId' | 'userId' | 'labels' | 'quoteInternalId' | 'notes'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  notes: { type: 'transportable', initial: '' },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  quoteInternalId: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
  quoteInternalId: identity,
});
