import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AppDatePicker } from '@components/calendar/AppDatePicker';
import { ModalChildProps } from '@components/AppModal';
import { toISODay } from '@utils/dates/to-iso-day';
import { ISlot } from './slot.interface';
import { formatTime } from '@utils/dates/format-time';
import { formatDateTimeRange } from '@utils/dates/format-date-time-range';
import { GroupedByDay } from './group-slots-by-date';

const DaySlots = (pickersDayProps: PickersDayProps<Moment>, hasSlots: boolean): JSX.Element => (
  <PickersDay {...pickersDayProps} disabled={!hasSlots} />
);

type Props = {
  groupedByDay: GroupedByDay;
  isLoading: boolean;
  handleFormSubmit: (slot: ISlot) => void;
  handleMonthChange?: React.Dispatch<React.SetStateAction<Moment>>;
} & ModalChildProps;

/**
 * This component accepts grouped by day time slots
 * and render DayPicker where days without available slots are disabled.
 *
 * By default selected day is the day closest to today with available slots.
 *
 * When user clicks submit button, the slot will passed to handleFormSubmit callback to be processed in parent component
 */
export const ChooseTimeSlot: React.FC<Props> = ({ groupedByDay, isLoading, handleFormSubmit, handleMonthChange }) => {
  const [currentMoment, setCurrentMoment] = useState<Moment>(moment());
  const [currentSlot, setCurrentSlot] = useState<null | ISlot>(null);

  const getCurrentDaySlots = useCallback((day: Moment): ISlot[] => groupedByDay[toISODay(day)] ?? [], [groupedByDay]);

  const getHasSlots = useCallback(
    (props: PickersDayProps<Moment>): boolean => getCurrentDaySlots(props.day).length > 0,
    [getCurrentDaySlots],
  );

  const slots = useMemo(() => getCurrentDaySlots(currentMoment), [currentMoment, getCurrentDaySlots]);

  useEffect(() => {
    const [earliestDay] = Object.keys(groupedByDay);
    setCurrentMoment(moment(earliestDay));
  }, [groupedByDay]);

  function onChange(date: Moment): void {
    setCurrentMoment(date);
    setCurrentSlot(null);
  }

  function handleSubmit(): void {
    if (currentSlot) {
      handleFormSubmit(currentSlot);
    }
  }

  const currentTimeTitle = currentSlot
    ? formatDateTimeRange(currentSlot.start, currentSlot.end)
    : 'First select time slot';

  return (
    <Box>
      <AppDatePicker
        isLoading={isLoading}
        value={currentMoment}
        onChange={onChange}
        onMonthChange={handleMonthChange}
        renderDay={(props): JSX.Element => DaySlots(props, getHasSlots(props))}
      />
      <Typography component="h2" variant="h6" sx={{ textAlign: 'center' }}>
        Start Time
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        {slots.map((slot, i) => (
          <Grid item key={i} xs={4} md={4} lg={3}>
            <Chip
              sx={{ width: '100%' }}
              label={formatTime(slot.start)}
              color="primary"
              variant={slot.start === currentSlot?.start ? 'filled' : 'outlined'}
              onClick={(): unknown => setCurrentSlot(slot)}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 1, textAlign: 'center' }}>{currentTimeTitle}</Box>
      <Button variant="contained" disabled={!currentSlot} onClick={handleSubmit} fullWidth sx={{ mt: 1 }}>
        Confirm Booking
      </Button>
    </Box>
  );
};
