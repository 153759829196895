import { createEnumOptions } from '@utils/create-enum.factory';

export const RepairAndReplaceTypes = {
  EquipmentDamaged: 'Damaged',
  EquipmentStolen: 'Stolen',
} as const;

type Keys = keyof typeof RepairAndReplaceTypes;

export type RepairAndReplaceType = (typeof RepairAndReplaceTypes)[Keys];

export const RepairAndReplaceTypeOptions = createEnumOptions(RepairAndReplaceTypes);
