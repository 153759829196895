import { createEnumOptions } from '@utils/create-enum.factory';

export const UserRoles = {
  operator: 'operator',
  admin: 'admin',
  customer: 'student',
  tutor: 'tutor',
  assessor: 'assessor',
  qualityAssurance: 'qualityAssurance',
  financeManager: 'financeManager',
} as const;

type Keys = keyof typeof UserRoles;

export type UserRoleType = (typeof UserRoles)[Keys];

export type CustomerRoleType = typeof UserRoles.customer;
export type TutorRoleType = typeof UserRoles.tutor;
export type OperatorRoleType = typeof UserRoles.operator;
export type AdminRoleType = typeof UserRoles.admin;
export type AssessorRoleType = typeof UserRoles.assessor;
export type QualityAssuranceType = typeof UserRoles.qualityAssurance;
export type FinanceManagerType = typeof UserRoles.financeManager;

export type SignableRoleType = CustomerRoleType | TutorRoleType;

export const UserRoleOptions = createEnumOptions(UserRoles);
