import { identity } from 'lodash';
import { IOrder } from '@features/order/order.interface';
import { nullToString } from '@utils/null-to-string';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { IOrderSummary } from '../order-summary.interface';
import { emptyStringToNull } from '@utils/empty-string-to-null';
import { toBooleanOrNull } from '@utils/to-boolean-or-null';
import { Sort } from '@features/sorting/sort.type';

export type FormModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  labels: IOrder['labels'][];
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
  customerEmail: string;
  customerContactNumber: string;
  isCancelled: boolean | null;
  daysInLastStatus: Sort | null;
};

export type ApiModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  labels: IOrder['labels'][];
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
  customerEmail: string | null;
  customerContactNumber: string | null;
  isCancelled: boolean | null;
  daysInLastStatus: Sort | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
  customerEmail: { type: 'transportable', initial: '' },
  customerContactNumber: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: null },
  daysInLastStatus: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: nullToString,
  orderStatusId: nullToString,
  organisationId: nullToString,
  labels: nullToString,
  customerFirstName: emptyStringToNull,
  customerLastName: emptyStringToNull,
  customerCrn: emptyStringToNull,
  customerEmail: emptyStringToNull,
  customerContactNumber: emptyStringToNull,
  isCancelled: toBooleanOrNull,
  daysInLastStatus: identity,
});
