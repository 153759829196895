import { identity } from 'lodash';
import { ICustomer } from '@features/customer/customer.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<
  ICustomer,
  | 'address'
  | 'altNumber'
  | 'contactNumber'
  | 'crn'
  | 'email'
  | 'firstName'
  | 'fundingBodyId'
  | 'hepId'
  | 'lastName'
  | 'notes'
  | 'postcode'
  | 'prefCommunicationWay'
  | 'extraMile'
>;

export type ApiModel = FormModel;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  address: { type: 'transportable', initial: '' },
  altNumber: { type: 'transportable', initial: '' },
  contactNumber: { type: 'transportable', initial: '' },
  crn: { type: 'transportable', initial: '' },
  email: { type: 'transportable', initial: '' },
  firstName: { type: 'transportable', initial: '' },
  fundingBodyId: { type: 'backend-label', optionKey: 'fundingBodyOptions', initial: null },
  hepId: { type: 'backend-label', optionKey: 'hepOptions', initial: null },
  lastName: { type: 'transportable', initial: '' },
  notes: { type: 'transportable', initial: '' },
  postcode: { type: 'transportable', initial: '' },
  prefCommunicationWay: {
    type: 'backend-label',
    optionKey: 'preferredCommunicationWaysOptions',
    multiple: true,
    initial: [],
  },
  extraMile: { type: 'transportable', initial: false },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  address: identity,
  altNumber: identity,
  contactNumber: identity,
  crn: identity,
  email: identity,
  firstName: identity,
  fundingBodyId: identity,
  hepId: identity,
  lastName: identity,
  notes: identity,
  postcode: identity,
  prefCommunicationWay: identity,
  extraMile: toBoolean,
});
