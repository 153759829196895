import { IOrderNmh } from './order-nmh.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { Moment } from 'moment-timezone';
import { toISODay } from '@utils/dates/to-iso-day';

export type FormModel = Pick<
  IOrderNmh,
  'orderStatusId' | 'userId' | 'labels' | 'notes' | 'levelOfStudy' | 'intensity' | 'organisationId'
> & {
  acceptanceLetterFile: File | null;
  courseEndDate: Moment | null;
  equipmentDeliveryDate: Moment | null;
};

export type ApiModel = Pick<
  IOrderNmh,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'levelOfStudy'
  | 'intensity'
  | 'courseEndDate'
  | 'organisationId'
  | 'equipmentDeliveryDate'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  levelOfStudy: { type: 'backend-label', optionKey: 'levelOfStudyOptions', initial: null },
  intensity: { type: 'backend-label', optionKey: 'intensityOptions', initial: null },
  courseEndDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  equipmentDeliveryDate: { type: 'date', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
  levelOfStudy: identity,
  intensity: identity,
  courseEndDate: toISODay,
  organisationId: identity,
  equipmentDeliveryDate: toISODay,
});
