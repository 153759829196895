import { IOrderTechnical } from './order-technical.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { Moment } from 'moment-timezone';
import { toISODay } from '@utils/dates/to-iso-day';
import { toBoolean } from '@utils/to-boolean';
import { SystemOptions } from '@features/order/shared/system.type';
import { InstallationOptions } from '@features/order/shared/installation.type';

export type FormModel = Pick<
  IOrderTechnical,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'isContributePayable'
  | 'organisationId'
  | 'warranty'
  | 'quoteId'
  | 'paymentLink'
  | 'salesId'
  | 'invoiceNumber'
  | 'isUpgradedEquipment'
  | 'dsa2ApprovedAmount'
  | 'upgradePrice'
  | 'system'
  | 'installationType'
  | 'immyBotInstallationLink'
> & {
  acceptanceLetterFile: File | null;
  pickUp: Moment | null;
  arranged: Moment | null;
  quotationFile: File | null;
};

export type ApiModel = Pick<
  IOrderTechnical,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'isContributePayable'
  | 'organisationId'
  | 'warranty'
  | 'quoteId'
  | 'paymentLink'
  | 'salesId'
  | 'pickUp'
  | 'arranged'
  | 'invoiceNumber'
  | 'isUpgradedEquipment'
  | 'dsa2ApprovedAmount'
  | 'upgradePrice'
  | 'system'
  | 'installationType'
  | 'immyBotInstallationLink'
> & {
  acceptanceLetterFile: File | null;
  quotationFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  isContributePayable: { type: 'transportable', initial: false },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  warranty: { type: 'backend-label', optionKey: 'warrantyOptions', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  paymentLink: { type: 'transportable', initial: '' },
  salesId: { type: 'transportable', initial: '' },
  pickUp: { type: 'date', initial: null },
  arranged: { type: 'date', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
  quotationFile: { type: 'transportable', initial: null },
  isUpgradedEquipment: { type: 'transportable', initial: false },
  dsa2ApprovedAmount: { type: 'transportable', initial: '' },
  upgradePrice: { type: 'transportable', initial: '' },
  system: { type: 'enum', options: SystemOptions, initial: null },
  installationType: { type: 'enum', options: InstallationOptions, initial: null },
  immyBotInstallationLink: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
  isContributePayable: toBoolean,
  organisationId: identity,
  warranty: identity,
  quoteId: identity,
  paymentLink: identity,
  salesId: identity,
  pickUp: toISODay,
  arranged: toISODay,
  invoiceNumber: identity,
  quotationFile: identity,
  isUpgradedEquipment: toBoolean,
  dsa2ApprovedAmount: identity,
  upgradePrice: identity,
  system: identity,
  installationType: identity,
  immyBotInstallationLink: identity,
});
