import {
  Describe,
  array,
  assign,
  boolean,
  enums,
  literal,
  nullable,
  number,
  object,
  pattern,
  string,
} from 'superstruct';
import { IOrderTechnical } from '../order-technical.interface';
import { BaseOrderStruct } from '@features/order/base/spec/base-order-struct.spec';
import { OrderClasses } from '@features/order/order-class.type';
import { ISODateRegexp } from '@utils/dates/iso-string.type';
import { FileStruct } from '@utils/superstruct/file-struct';
import { ElectronicInvoiceLogStruct } from '@features/electronic-invoice/electronic-invoice-struct';
import { EquipmentQuoteLineStruct } from '@features/order/shared/order-lines/spec/equipment-quote-line-struct.spec';
import { SalesOrderLineStruct } from '@features/order/shared/order-lines/spec/sales-order-line-struct.spec';
import { Systems } from '@features/order/shared/system.type';
import { Installations } from '@features/order/shared/installation.type';

export const TechnicalOrderStruct: Describe<IOrderTechnical> = assign(
  BaseOrderStruct,
  object({
    orderClass: literal(OrderClasses.Technical),
    isContributePayable: boolean(),
    organisationId: nullable(number()),
    warranty: nullable(number()),
    quoteId: nullable(string()),
    quotationFilePath: nullable(string()),
    quotationFile: nullable(FileStruct),
    paymentLink: nullable(string()),
    salesId: nullable(string()),
    pickUp: nullable(pattern(string(), ISODateRegexp)),
    arranged: nullable(pattern(string(), ISODateRegexp)),
    equipmentQuoteLines: array(EquipmentQuoteLineStruct),
    salesOrderLines: array(SalesOrderLineStruct),
    electronicInvoiceLogs: array(ElectronicInvoiceLogStruct),
    unleashedStatus: nullable(string()),
    isUpgradedEquipment: boolean(),
    dsa2ApprovedAmount: nullable(number()),
    upgradePrice: nullable(number()),
    system: nullable(enums(Object.values(Systems))),
    installationType: nullable(enums(Object.values(Installations))),
    immyBotInstallationLink: nullable(string()),
  }),
);
