import { Describe, array, boolean, enums, nullable, number, object, string } from 'superstruct';
import { IOrderBase } from '../order-base.interface';
import { FileStruct } from '@utils/superstruct/file-struct';
import { OrderClasses } from '../../order-class.type';

export const BaseOrderStruct: Describe<IOrderBase> = object({
  id: number(),
  orderTypeId: nullable(number()),
  orderClass: nullable(enums(Object.values(OrderClasses))),
  orderStatusId: nullable(number()),
  userId: nullable(number()),
  labels: array(number()),
  notes: nullable(string()),
  acceptanceFilePath: nullable(string()),
  acceptanceLetterFile: nullable(FileStruct),
  customerId: number(),
  studentId: number(),
  electronicInvoice: nullable(string()),
  invoiceNumber: nullable(string()),
  isCancelled: boolean(),
});
