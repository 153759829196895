import { identity } from 'lodash';
import { IOrderNew } from './order-new.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';

export type FormModel = Pick<IOrderNew, 'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export type ApiModel = Pick<IOrderNew, 'orderTypeId' | 'orderStatusId' | 'userId' | 'labels' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', initial: null },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: identity,
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
});
