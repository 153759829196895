import { identity } from 'lodash';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  productCategoryId: number[];
  query: string;
};

export type ApiModel = {
  productCategoryId: number[];
  query: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  productCategoryId: { type: 'backend-label', optionKey: 'productCategory', multiple: true, initial: [] },
  query: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  productCategoryId: identity,
  query: emptyStringToNull,
});
