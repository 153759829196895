import React from 'react';
import { NavLink } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { useFilteredQuotes } from './filter-quote-form/use-filtered-quotes';
import { Routes } from '@routes/routes';
import { Box, Link, Typography } from '@mui/material';
import { IQuote } from './quote.interface';
import { useAppTitle } from '@hooks/use-app-title';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDate } from '@utils/dates/format-date';
import { AppPaper } from '@components/AppPaper';
import { AppDataGrid } from '@components/AppDataGrid';
import { QuoteStatusOptions } from '@features/quote/quote-status.type';
import { renderEnum } from '@utils/render-enum';
import { FilterQuotesForm } from './filter-quote-form/FilterQuotesForm';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createQuoteColumn = createColumnFactory<IQuote>();

const columns: GridColDef[] = [
  createQuoteColumn('id', {
    headerName: 'Quote ID',
    width: 147,
    renderCell: ({ row: quote }) => (
      <Link component={NavLink} to={`${Routes.quotes}/${quote.quoteInternalId}`}>
        {quote.quoteInternalId}
      </Link>
    ),
  }),
  createQuoteColumn('crn', {
    headerName: 'CRN',
    width: 147,
  }),
  createQuoteColumn('createdAt', {
    headerName: 'Created',
    width: 147,
    renderCell: ({ row }) => formatDate(row.createdAt),
  }),
  createQuoteColumn('exVat', {
    headerName: 'Ex VAT',
    width: 147,
  }),
  createQuoteColumn('inclVat', {
    headerName: 'Inc VAT',
    width: 147,
  }),
  createQuoteColumn('status', {
    headerName: 'Status',
    width: 147,
    renderCell: ({ row }) => renderEnum(row.status, QuoteStatusOptions),
  }),
];

export const QuoteListPage: React.FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useFilteredQuotes,
    PageSizeNames.Quote,
  );
  useAppTitle('Quotes');

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Quotes
      </Typography>
      <Box sx={{ mt: 1 }}>
        <FilterQuotesForm />
      </Box>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
