import { createEnumOptions } from '@utils/create-enum.factory';

export const RecordTypes = {
  Email: 'email',
  Phone: 'phone',
  Action: 'action',
} as const;

type Keys = keyof typeof RecordTypes;

export type RecordTypeType = (typeof RecordTypes)[Keys];

export const RecordTypeOptions = createEnumOptions(RecordTypes);
