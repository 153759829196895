import React from 'react';
import { ModalChildProps } from '@components/AppModal';
import { useZohoFreeSlots } from '@features/automatic-booking/use-zoho-free-slots';
import { ISlot } from '@features/automatic-booking/slot.interface';
import { useSubmitSessionAutomatedBooking } from '@features/automatic-booking/use-submit-session-automated-booking';
import { ChooseTimeSlot } from '@features/automatic-booking/ChooseTimeSlot';

type Props = {
  sessionId: number | null;
  allocationId: number;
  qualification: string | number;
  tutorId: number | null;
} & ModalChildProps;

// Now hardcoded, later should be get from the labels
const DurationByQualification: Record<string | number, number> = {
  mentoring: 1,
  study: 1,
  att: 2,
  study_asc: 1,
  mentoring_asc: 1,
} as const;

export const ChooseSessionTimeSlot: React.FC<Props> = ({
  sessionId,
  allocationId,
  qualification,
  onClose,
  tutorId,
}) => {
  const duration = DurationByQualification[qualification] ?? 1;

  const { handleFormSubmit } = useSubmitSessionAutomatedBooking(sessionId, allocationId, onClose);
  const { groupedByDay, offeredDate, handleMonthChange, isLoading } = useZohoFreeSlots(
    { qualification, tutorId },
    duration,
  );

  function onSubmit(slot: ISlot): void {
    handleFormSubmit({
      startMoment: slot.start,
      duration,
      offeredDate,
    });
  }

  return (
    <ChooseTimeSlot
      handleFormSubmit={onSubmit}
      groupedByDay={groupedByDay}
      isLoading={isLoading}
      onClose={onClose}
      handleMonthChange={handleMonthChange}
    />
  );
};
