import { IOrderNeedsAssessment } from './order-needs-assessment.interface';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { identity } from 'lodash';
import { Moment } from 'moment-timezone';
import { toISODateTimeFlooredToMinutes } from '@utils/dates/to-iso-date-time-floored-to-minutes';
import { toISODay } from '@utils/dates/to-iso-day';
import { toBoolean } from '@utils/to-boolean';

export type FormModel = Pick<
  IOrderNeedsAssessment,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'assessmentType'
  | 'assessmentCentreId'
  | 'disabilityTypeIds'
  | 'conditionIds'
  | 'isReviewRequested'
  | 'shareNarWithHep'
  | 'quoteId'
  | 'qualityAssuranceUserId'
  | 'organisationId'
  | 'invoiceNumber'
> & {
  acceptanceLetterFile: File | null;
  nextContactDate: Moment | null;
  reportSentDate: Moment | null;
  submissionDate: Moment | null;
};

export type ApiModel = Pick<
  IOrderNeedsAssessment,
  | 'orderStatusId'
  | 'userId'
  | 'labels'
  | 'notes'
  | 'assessmentType'
  | 'assessmentCentreId'
  | 'disabilityTypeIds'
  | 'conditionIds'
  | 'isReviewRequested'
  | 'shareNarWithHep'
  | 'quoteId'
  | 'nextContactDate'
  | 'reportSentDate'
  | 'qualityAssuranceUserId'
  | 'submissionDate'
  | 'organisationId'
  | 'invoiceNumber'
> & {
  acceptanceLetterFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'transportable', initial: null },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
  assessmentType: { type: 'backend-label', optionKey: 'assessmentTypeOptions', initial: null },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', initial: null },
  disabilityTypeIds: { type: 'backend-label', optionKey: 'disabilityTypeOptions', multiple: true, initial: [] },
  conditionIds: { type: 'backend-label', optionKey: 'conditionOptions', multiple: true, initial: [] },
  isReviewRequested: { type: 'transportable', initial: false },
  shareNarWithHep: { type: 'transportable', initial: false },
  quoteId: { type: 'transportable', initial: '' },
  nextContactDate: { type: 'date', initial: null },
  reportSentDate: { type: 'date', initial: null },
  qualityAssuranceUserId: { type: 'backend-label', optionKey: 'userOptions', initial: null },
  submissionDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: identity,
  userId: identity,
  labels: identity,
  notes: identity,
  acceptanceLetterFile: identity,
  assessmentType: identity,
  assessmentCentreId: identity,
  disabilityTypeIds: identity,
  conditionIds: identity,
  isReviewRequested: toBoolean,
  shareNarWithHep: toBoolean,
  quoteId: identity,
  nextContactDate: toISODay,
  reportSentDate: toISODateTimeFlooredToMinutes,
  qualityAssuranceUserId: identity,
  submissionDate: toISODateTimeFlooredToMinutes,
  organisationId: identity,
  invoiceNumber: identity,
});
